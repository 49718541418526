<template>
  <div class="yunvideo">
    <!-- nav -->
    <div class="wrap">
      <div class="nav w">
        <ul>
          <li v-for="item in culture_cate_list" :key="item.id">
            <a
              @click="topClick(item.id)"
              :class="[idsTab == item.id ? 'active li' : 'li']"
              href="javascript:;"
            >{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- 图文 -->
    <div class="list w clearfix bomflex">
      <div class="list-left">
        <ul>
          <li :class="[isCheck == 1 ? 'check' : '']">
            <a
              :style="isCheck == 1 ? 'color:#fff' : ''"
              class="hoveTabLi"
              @click="bottomClick(1)"
            >{{$t('tea.image')}}</a>
          </li>
          <li :class="[isCheck == 2 ? 'check' : '']">
            <a
              :style="isCheck == 2 ? 'color:#fff' : ''"
              class="hoveTabLi"
              @click="bottomClick(2)"
            >{{$t('tea.video')}}</a>
          </li>
        </ul>
      </div>
      <!-- 第一个 -->
      <div v-for="cultur in cultureList" :key="cultur.id">
        <div v-if="isbottom == 1" class="list-right1 mb80 clearfix" @click="toDet(cultur.id, 1)">
          <div class="list-right1-wrap mt80">
            <div class="list-right1-left">
              <img :src="cultur.image" alt />
            </div>
            <div class="list-right1-right">
              <h5>{{cultur.title}}</h5>
              <p>
                <span v-html="cultur.content"></span>
              </p>
            </div>
          </div>
        </div>
        <empty v-if="!cultureList.length" />
      </div>
      <!-- 第二个 -->
      <div v-if="isbottom == 2" class="list-right mb80 clearfix">
        <template v-if="cultureList.length">
          <div
            class="list-right-wrap"
            v-for="cultur in cultureList"
            :key="cultur.id"
            @click="toDet(cultur.id, 2)"
          >
            <div class="wrapper"></div>
            <div class="img-wrap">
              <i class="ibg"></i>
              <img style="position:absolute" class="imgVideo" src="../assets/images/video.png" alt />
              <img :src="cultur.image" alt />
            </div>
            <p>{{ cultur.title }}</p>
          </div>
        </template>
        <empty v-else />
      </div>
    </div>
    <!-- 尾部 -->
    <!-- <Footer /> -->
    <!-- 分页 -->
    <el-pagination
      v-if="total!==0"
      background
      class="pageAction"
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :page-size="pageSize"
      :current-page="indexPage"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import { getTeaCultureList } from "@/api/index.js";
import empty from "@/components/emptyBox/index";
export default {
  components: {
    empty
  },
  data() {
    return {
      swiper: null,
      isimg: "1",
      isbottom: "1",
      page: 1,
      type: 1, //默认图文
      categoryId: 0,
      culture_cate_list: [],
      cultureList: [],
      isCheck: 1,
      idsTab: 0,
      total: 0,
      indexPage: 1,
      pageSize: 5
    };
  },
  mounted() {
    sessionStorage.setItem("important_bId_custom", 7);
    this.$store.commit('setBannerId', 7);
    this.getList(this.page, this.type, this.categoryId);
    this.idsTab = Number(this.$route.query.type);
  },
  methods: {
    handleCurrentChange(val) {
      this.indexPage = val;
      console.log(this.isimg,this,1888888)
      this.getList(this.page, this.type, this.categoryId, "onclick");
    },
    toDet(str, type = 1) {
      this.$router.push("/TeaDet?id=" + str + "&type=" + type);
    },
    topClick(categoryId) {
      // this.$router.push({
      //   name:'Tea',
      //   query:{
      //     type:categoryId
      //   }
      // })
      this.indexPage = 1;
      this.idsTab = categoryId;
      const datas = this.culture_cate_list.filter(item => {
        return item.isChecked === 1;
      });
      this.isimg = categoryId;
      this.categoryId = categoryId;

      this.getList(this.page, this.type, categoryId, "onclick");
    },
    bottomClick(type) {
      this.isbottom = type;
      this.indexPage = 1;
      this.type = type;
      this.isCheck = type;
      this.getList(this.page, type, this.categoryId);
    },
    getList(page, type, categoryId, on) {
      // console.log(page, type, categoryId, on, 99888888);
      getTeaCultureList({
        page: this.indexPage,
        type: this.type,
        categoryId:
          on === "onclick" ? Number(this.isimg) : Number(this.$route.query.type)
      }).then(res => {
        if (res.code == "200") {
          this.culture_cate_list = res.data.categoryList;
          this.cultureList = res.data.cultureList.data;
          this.total = res.data.cultureList.total;
        } else {
          // alert(res.message | '');
        }
      });
    }
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    }
  }
};
</script>

<style scoped  lang="scss">
.imgVideo{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  opacity: 0;
  width:50px;
}
.img-wrap{
  position: relative;
  cursor: pointer;
  transition: all .5s;
  overflow: hidden;
  max-height: 286px;
  .ibg{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0,0,0,0.4);
    opacity: 0;
  }
  
  &:hover{
    img{
      z-index: 2;
      &:last-child{
        transform: scale(1.1);
        transition: all .5s;
      }
    }
    .ibg{
      opacity: 1;
    }
    .imgVideo{
      opacity: 1;
    }
  }
  img{
    &:last-child{
      width:100%;
    }
  }
}
.pageAction {
  padding: 40px;
  text-align: center;
  ::v-deep {
    .active {
      background-color: #0e9b32 !important;
    }
    li {
      &:hover {
        color: #27ea58 !important;
      }
    }
  }
}
.bomflex {
  padding-bottom: 35px;
}
.wrap {
  background: #f5f5f5;
  height: 100px;

  .nav {
    ul li {
      float: left;
      position: relative;
      .li {
        position: relative;
        display: inline-block;
        text-decoration: none;
        font-size: 26px;
        color: #4c4c4c;
        height: 100px;
        line-height: 100px;
        margin-right: 80px;
      }

      .active {
        color: #0ba539;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: #0ba539;
          display: inline-block;
        }
      }
    }
  }
}

.list {
  margin-top: 60px;
  // height: 1000px;
  // background: #0E9B32;

  .list-left {
    float: left;
    min-width: 140px;
    max-width: 200px;

    ul {
      li {
        height: 80px;
        border: 1px #999999 solid;
        border-bottom: none;
        :last-child {
          border-bottom: 1px #999999 solid;
        }

        &:hover {
          background: #0e9b32;

          a {
            color: #fff;
          }
        }

        a {
          line-height: 80px;
          text-decoration: none;
          color: #333333;
          font-size: 22px;
          display: block;
          padding: 0 20px;
          // box-sizing: border-box;
        }
      }
    }
  }
  .list-right1 {
    float: right;
    width: 950px;
    //  height: 1000px;
    //  background: lightcoral;
    transition: all 0.5s;
    cursor: pointer;

    .list-right1-wrap {
      width: 950px;
      height: 257px;
      // background: burlywood;
      margin-bottom: 40px;
      overflow: hidden;

      .list-right1-left {
        float: left;
        width: 386px;
        overflow: hidden;
        //  height:400px;
        //  background: red;
        margin-right: 35px;
        img {
          width: 100%;
        }
        &:hover {
          img {
            transition: all 0.5s;
            transform: scale(1.2);
          }
        }
      }

      .list-right1-right {
        float: left;
        width: 505px;
        //  height:400px;
        //  background: yellow;

        h5 {
          font-weight: 600;
          color: #3a3a3a;
          line-height: 30px;
          font-size: 22px;
          padding-top: 53px;
          box-sizing: border-box;
        }
        p {
          font-weight: 400;
          color: #a2a2a2;
          line-height: 20px;
          font-size: 18px;
          line-height: 2;
          margin-top: 37px;
        }
      }
    }
  }

  .list-right {
    float: right;
    // height: 1000px;
    width: 951px;
    // background: lightcoral;

    .list-right-wrap {
      float: left;
      width: 375px;
      height: 285px;
      // background: cadetblue;
      margin-right: 50px;
      position:relative;

      p {
        font-weight: 600;
        color: #3a3a3a;
        line-height: 22px;
        padding-top: 18px;
        box-sizing: border-box;
        position:absolute;
        bottom:40px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.swiper-container {
  height: 500px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      // background-color: #42b983;
      text-align: center;
      line-height: 500px;
      height: 100%;
      width: 100%;
      background: url(../assets/images/banner4.jpg) no-repeat center top;
      background-size: 1920px 500px;
    }
  }
}

.check {
  background: #0e9b32;
}
.hoveTabLi {
  cursor: pointer;
}
</style>